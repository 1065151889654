import React, { Component } from 'react'

import Link from '../utils/link'
import Fade from 'react-reveal/Fade'


class Footer extends Component {
  render() {

    let { wp } = this.props

    return (

        <footer id='contact' className='footer'>
          <div className='footer__inner'>

            <div className='footer__col'>

            </div>
              <div className='footer__col'>
              <Fade bottom distance={'40px'} delay={500}>
                <p>
                  7001<br/>
                  MELROSE AVE<br/>
                  LOS ANGELES CA<br/>
                  90038
                </p>
              </Fade>
              </div>
            <div className='footer__col'>
            <Fade bottom distance={'40px'} delay={500}>
              <div className='header__col--inner'>
                <h3>BY APPOINTMENT</h3>
                <div dangerouslySetInnerHTML={{ __html: wp.siteSettings.acf.hours }} />

                <Link className='footer__link' to={wp.siteSettings.acf.instagramUrl}>INSTAGRAM</Link>
                {/* { console.log(wp) } */}
              </div>
            </Fade>
            </div>

            <div className='footer__col'>
              <Fade bottom distance={'40px'} delay={500}>
              <div>
              <h3>CONTACT</h3>

              <a href={"mailto:" + wp.siteSettings.acf.appointmentEmail }>{wp.siteSettings.acf.appointmentEmail}</a>
              <br/><br/>
              <h3>PHONE</h3>

              <a href="tel:3235910156">323 591 0156</a>

              {/* <h3 class="sales">SALES + INQUIRY</h3>
              <div dangerouslySetInnerHTML={{ __html: wp.siteSettings.acf.salesAndInquiry }} /> */}
              </div>
              </Fade>
            </div>

            <div className='footer__col'>

            </div>

          </div>
        </footer>
    )
  }
}

export default Footer