import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
//import Client from 'shopify-buy'

import '../assets/scss/main.scss'

import Header from '../components/header'
import Footer from '../components/footer'

if (typeof window !== 'undefined') {
	// eslint-disable-next-line global-require
	require('smooth-scroll')('a[href*="#"]', {
		speed: 800,
		speedAsDuration: true,
		easing: 'easeInOutCubic'
	})
}

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
        wp {
          siteSettings {
            acf {
              hours
              instagramUrl
              appointmentEmail
              salesAndInquiry
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <>
          <div className='site-wrapper'>
            <Header {...data} />
            <main>{ children }</main>
            <Footer {...data} />
          </div>

        </>
      )
    }
    }
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
