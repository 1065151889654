exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-templates-designer-js": () => import("./../../../src/templates/designer.js" /* webpackChunkName: "component---src-templates-designer-js" */),
  "component---src-templates-new-arrivals-js": () => import("./../../../src/templates/new-arrivals.js" /* webpackChunkName: "component---src-templates-new-arrivals-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-type-js": () => import("./../../../src/templates/type.js" /* webpackChunkName: "component---src-templates-type-js" */)
}

