import React, { Component } from 'react';
import { graphql, Link } from 'gatsby';
import he from 'he';
import Fade from 'react-reveal/Fade';
import elasticlunr from 'elasticlunr';
import 'url-search-params-polyfill';
import unorm from 'unorm'; // Import unorm library

import SEO from '../components/seo';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

class SearchPage extends Component {
  search = null;

  constructor(props) {
    super(props);

    const urlParams = new URLSearchParams(this.props.location.search);

    this.state = {
      query: urlParams.get('search') || '',
      results: [],
      length: 9999,
    };
  }




  _register() {
    let index = elasticlunr()
    index.addField('title')
    index.addField('content')
    index.addField('designer') // Add the 'designer' field to the index
    index.setRef('id')
  
    this.props.data.blog.edges.forEach((el) => {
      // Normalize text before indexing
      const normalizedTitle = unorm.nfd(el.node.title).replace(/[\u0300-\u036f]/g, "").toLowerCase();
      const normalizedDesigner = unorm.nfd(el.node.acf.designer).replace(/[\u0300-\u036f]/g, "").toLowerCase(); // Normalize designer
      console.log("Normalized title:", normalizedTitle);
      console.log("Normalized designer:", normalizedDesigner);
      index.addDoc({ ...el.node, title: normalizedTitle, designer: normalizedDesigner });
    })
  
    this.search = index
  
    //console.log(index);
    //console.log("Search Index:", this.search.toJSON());
  }
  
  
  componentDidMount() {
    this._register()
  
    if (this.state.query) {
      // Normalize query before searching
      const normalizedQuery = unorm.nfd(this.state.query).replace(/[\u0300-\u036f]/g, "").toLowerCase();
      //console.log("Normalized query:", normalizedQuery); // Log the normalized query
      
      // Add wildcard to the normalized query to allow partial matches
      const wildcardQuery = `*${normalizedQuery}*`;
      //console.log("Wildcard query:", wildcardQuery); // Log the wildcard query
  
      this.setState({
        results: this.search.search(wildcardQuery)
          .map(({ ref }) => this.search.documentStore.getDoc(ref)),
      });
    }
  }
  

  _renderResult = (el, i) => {
    if (el.node) el = el.node;
    return (
      <Fade bottom distance={'40px'} delay={500} key={i}>
        <div className={'result result__item item__' + i}>
          <div className="result__inner">
            <Link className='result__link' to={el.type === 'post' ? `/article/${el.slug}` : `/product/${el.slug}`}>
              {el.acf.featuredImage && (
                <GatsbyImage
                  className='earch-results__image'
                  loading='lazy'
                  image={getImage(el.acf.featuredImage.localFile)}
                  alt={he.decode(el.title)}
                />
              )}
              <div className="result__meta">
                <h2 className='title'>{he.decode(el.title)}</h2>
              </div>
            </Link>
          </div>
        </div>
      </Fade>
    );
  };

  render() {
    let { results, length, query } = this.state;

    if (results.length === 0) {
      setTimeout(() => this.refs.input && this.refs.input.focus(), 500);
    }

    return (
      <>
        <SEO title={'Search'} bodyClass="search" />
        <section className='search-results'>
          <div className='search-results__inner'>
            <Fade bottom distance={'40px'} delay={500}>
              <h1>Search result for: {query}</h1>
            </Fade>
          </div>
          <div className='results-container'>
            <>
              {results.length === 0 && (
                <Fade bottom distance={'40px'} delay={500}>
                  <div className='search-results__item'>
                    <h4>No results found.</h4>
                  </div>
                </Fade>
              )}
              {results.length !== 0 && results.slice(0, length).map(this._renderResult)}
            </>
          </div>
        </section>
      </>
    );
  }
}

export default SearchPage;

export const searchQuery = graphql`
  query {
    blog: allWpProduct {
      edges {
        node {
          id
          uri
          slug
          title
          acf {
            designer
            featuredImage {
              id
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 95
                    width: 900
                    formats: [AUTO, WEBP]
                    placeholder: DOMINANT_COLOR
                  )
                }
              }
            }
          }
        }
      }
    }
  }
`;
